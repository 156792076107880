
// Used to toggle which context menus have the ability to be pulled up
// Only matters in the case where there are many of this component on one page
let allowDict: { [id: string] : boolean; } = {};

export default class ContextMenuHelper {
    initContextMenuListener(el: HTMLElement, menu: HTMLDivElement, groupId: string, initShouldDisable: boolean) {
        // prevents component flicker on page render
        menu.hidden = true;
        menu.removeAttribute('style');
        menu.setAttribute('class', 'fixed');

        allowDict[groupId] = !initShouldDisable;

        function disableListener() {
            menu.hidden = true;
        }

        document.addEventListener('click', disableListener);

        function contextMenuListener(ev: MouseEvent) {
            ev.preventDefault();
            if (!allowDict[menu.id]) return;
            menu.style.top = ev.clientY + "px";
            menu.style.left = ev.clientX + "px";
            menu.hidden = false;
            menu.focus();
            return;
        }
        el.addEventListener('contextmenu', contextMenuListener);

        return {
            destroy: () => {
                document.removeEventListener('click', disableListener);
                window.removeEventListener('scroll', disableListener);
                el.removeEventListener('contextmenu', contextMenuListener);
                el.removeEventListener('blur', disableListener);
            },
            updateContextAllow: (groupId: string, shouldDisable: boolean) => {
                allowDict[groupId] = !shouldDisable;
            }
        }
    }
}
