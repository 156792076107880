import interact from 'interactjs'

import {DotNetObjectReference} from "./DotnetObjectReference";


type CoordinatePair = {
    x: number, y: number
}

function getTopLeftCorner(element: HTMLElement): CoordinatePair {
    const rect = element.getBoundingClientRect();
    return {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY
    };
}
function getChildrenCoords(element: HTMLElement): CoordinatePair[] {
    return Array.from(element.children).map(getTopLeftCorner);
}

function findClosest(pair: CoordinatePair, pairs: CoordinatePair[]): number {
    return pairs.reduce(({ sqDistance, index }, currentPair, currentIndex) => {
        const newSqDistance = Math.pow(Math.abs(currentPair.x - pair.x), 2) +
            Math.pow(Math.abs(currentPair.y - pair.y), 2);
        if (newSqDistance < sqDistance) {
            return { sqDistance: newSqDistance, index: currentIndex }
        }
        else return { sqDistance, index }
    }, { sqDistance: Number.POSITIVE_INFINITY, index: -1 }).index
}

type SortableContext = {
    update: () => void;
}

function initDraggable(element: HTMLElement, listKey: string, onRemoved: (key: string) => void) {
    element.dataset.dndListKey = listKey;
    const position = { x: 0, y: 0 }

    const render = () => {
        element.style.transform =
            `translate(${position.x}px, ${position.y}px)`
    }

    interact(element).draggable({
        allowFrom: element.querySelector('[data-dnd-draghandle]') ? '[data-dnd-draghandle]' : undefined,
        listeners: {
            start (event) {
                const rect = element.getBoundingClientRect();
                event.target.dataset.dndDraggingActive = true;
                element.style.position = 'fixed';
                element.style.top = '0';
                element.style.left = '0';
                position.x = rect.left;
                position.y = rect.top;
                render();
            },
            move (event) {
                position.x += event.dx
                position.y += event.dy

                render();
            },
            end (event) {
                const { relatedTarget: dropzoneElement, target: draggableElement } = event;
                if (dropzoneElement?.dataset?.dndListKey &&
                    dropzoneElement.dataset.dndListKey !== draggableElement.dataset.dndListKey) {
                    onRemoved(draggableElement.dataset.dndKey);
                }

                position.x = 0;
                position.y = 0;
                render()
                element.removeAttribute('style');
                delete event.target.dataset.dndDraggingActive;
                delete event.target.dataset.dndOverDropZone;
            }
        }
    })
}

export default class Sortable {
    // noinspection JSUnusedGlobalSymbols -- invoked from C#
    init(element: HTMLElement, listKey: string, group: string, draggableClass: string, listeners: DotNetObjectReference): SortableContext {
        let childCoords: CoordinatePair[] = [];
        const recomputeCoords = () => childCoords = getChildrenCoords(element);

        element.dataset.dndDraggableClass = draggableClass;

        const initDraggables = () => {
            Array.from(element.children)
                .map(e => (e as HTMLElement))
                .filter(e => e.dataset.dndGroup && (e.dataset.dndListKey !== listKey))
                .map(e => initDraggable(
                    e as HTMLElement,
                    listKey,
                    k => listeners.invokeMethodAsync("OnItemRemovedCallback", k))
                );
        }

        initDraggables();

        let placeholder: HTMLElement | null = null;

        element.dataset.dndListKey = listKey;
        const renderPlaceholder = (index: number, factory: () => HTMLElement) => {
            if (placeholder?.parentNode === element) {
                element.removeChild(placeholder);
            }
            else {
                placeholder = factory();
                placeholder.removeAttribute('style');
                placeholder.setAttribute('class', element.dataset.dndDraggableClass);
                placeholder.style.opacity = "0.5";
                placeholder.style.transform = '';
                delete placeholder.dataset.dndDraggingActive;
                placeholder.dataset.dndPlaceholder = 'true';
            }

            if (index >= element.children.length) {
                element.appendChild(placeholder);
            } else {
                const referenceChild = element.children[index];
                element.insertBefore(placeholder, referenceChild);
            }
        }

        const clearPlaceholder = () => {
            placeholder?.remove();
            placeholder = null;
        }

        element.dataset.dndGroup = group;
        interact(element).dropzone({
            accept: `[data-dnd-group=${group}]`,//Only accept draggables from same group
            overlap: 'pointer',
            ondropactivate: function (event) {
                // add active dropzone feedback
                event.target.dataset.dndDropActive = true;
                recomputeCoords();
            },
            ondragenter: function (event) {
                const { relatedTarget: draggableElement, target: dropzoneElement } = event;

                // feedback the possibility of a drop
                dropzoneElement.dataset.dndDropTarget = true;
                draggableElement.dataset.dndOverDropZone = true;

                // fire enter event
                const { dndKey, dndGroup } = draggableElement.dataset;
                if (dndGroup === dropzoneElement.dataset.dndGroup) {
                    const _ = listeners.invokeMethodAsync("OnEnterCallback", dndKey);
                }
            },
            ondropmove: function (event) {
                const { relatedTarget: draggableElement } = event;
                const closestIndex= findClosest(getTopLeftCorner(draggableElement), childCoords);
                const closestIndexStr = ""+closestIndex;
                draggableElement.dataset.dndSortableIndex = closestIndexStr;
                renderPlaceholder(closestIndex, () => draggableElement.cloneNode(true));
            },
            ondragleave: function (event) {
                const { relatedTarget: draggableElement, target: dropzoneElement } = event;

                // remove the drop feedback style
                delete dropzoneElement.dataset.dndDropTarget;
                delete draggableElement.dataset.dndOverDropZone;
                clearPlaceholder();
            },
            ondrop: function (event) {
                const { relatedTarget: draggableElement, target: dropzoneElement } = event;

                const { dndKey, dndGroup, dndSortableIndex, dndListKey } = draggableElement.dataset;
                if (dndGroup === dropzoneElement.dataset.dndGroup) {
                    if (dndListKey === dropzoneElement.dataset.dndListKey) {
                        //Moving within the same list
                        const _ = listeners.invokeMethodAsync("OnItemMovedCallback", dndKey, +dndSortableIndex);
                    }
                    else {
                        //Moving from another list
                        const _ = listeners.invokeMethodAsync("OnItemAddedCallback", dndKey, +dndSortableIndex);
                        if (!dropzoneElement.contains(draggableElement)) draggableElement.classList.add('hidden');
                    }
                    clearPlaceholder();
                }
            },
            ondropdeactivate: function (event) {
                // remove active dropzone feedback
                delete event.target.dataset.dndDropActive;
                delete event.target.dataset.dndDropTarget;
            }
        });
        return {
            //Something something poor-man's objects
            update: () => {
                recomputeCoords();
                initDraggables();
            }
        };
    }
}
