export default class ContextMenuHelper {
    initContextMenuListener(el: HTMLElement, menu: HTMLDivElement) {
        function clickListener() {
            menu.hidden = true;
        }
        document.addEventListener('click', clickListener);

        function contextMenuListener(ev: MouseEvent) {
            ev.preventDefault();
            menu.style.top = ev.clientY + "px";
            menu.style.left = ev.clientX + "px";
            menu.hidden = false;
            menu.focus();
            return false;
        }
        el.addEventListener('contextmenu', contextMenuListener);

        return {
            destroy: () => {
                document.removeEventListener('click', clickListener);
                el.removeEventListener('contextmenu', contextMenuListener);
            }
        }
    }
}
