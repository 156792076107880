import interact from 'interactjs'

import {DotNetObjectReference} from "./DotnetObjectReference";


type CoordinateData = {
    x: number, y: number, index: number, visible: boolean
}

function getTopLeftCorner(element: HTMLElement, ind: number = 0, isVisible: boolean = true): CoordinateData {
    const rect = element.getBoundingClientRect();
    return {
        x: rect.left + window.scrollX,
        y: rect.top + window.scrollY,
        index: ind,
        visible: isVisible
    };
}

function isElementVisible(element: HTMLElement, scrollContainerId: string) {
    const ancestor = element.closest(scrollContainerId);
    // Only care if theres an ancestor that handles the sortables scrolling
    if (!ancestor) return true;

    const containerRect = ancestor.getBoundingClientRect();
    const topLeft = getTopLeftCorner(element);

    // Check if the element is within the visible bounds of the container
    return (topLeft.y >= containerRect.top && topLeft.y <= containerRect.bottom);
}

function getChildrenCoords(element: HTMLElement, scrollContainerId: string): CoordinateData[] {
    return Array.from(element.children).map((el: HTMLElement, index) => {
        let vis = isElementVisible(el, scrollContainerId);
        return getTopLeftCorner(el, index, vis);
    });
}

function findClosest(pair: CoordinateData, pairs: CoordinateData[]): number {
    return pairs.reduce(({ sqDistance, index }, currentPair) => {
        const newSqDistance = Math.pow(Math.abs(currentPair.x - pair.x), 2) +
            Math.pow(Math.abs(currentPair.y - pair.y), 2);
        if (newSqDistance < sqDistance && currentPair.visible) {
            return { sqDistance: newSqDistance, index: currentPair.index }
        }
        else return { sqDistance, index }
    }, { sqDistance: Number.POSITIVE_INFINITY, index: -1 }).index
}

type SortableContext = {
    update: () => void;
}

function initDraggable(element: HTMLElement, listKey: string, showCount: boolean, showCountClass: string, onSelect: (key: string) => void, onShiftSelect: (key: string) => void, onCtrlSelect: (key: string) => void) {
    element.dataset.dndListKey = listKey;
    const position = { x: 0, y: 0 }

    const render = () => {
        element.style.transform =
            `translate(${position.x}px, ${position.y}px)`
    }

    interact(element).on('tap', function(event: Interact.InteractEvent) {
        let key = element.dataset.dndKey;

        // right click gives unwanted behavior here so we'll return early
        if (event.button === 2) return;

        if (event.shiftKey) {
            // Action if Shift key is pressed
            onShiftSelect(key)
        } else if (event.ctrlKey) {
            // Action if Control key is pressed
            onCtrlSelect(key);
        } else {
            // Action if no key is pressed
            onSelect(key)
        }
    });

    let countElement: HTMLElement | null = null;
    function makeCountElement(count: number) {
        const countDiv: HTMLDivElement = document.createElement('div');

        countDiv.textContent = count + " rows";
        countDiv.id = 'count-element';
        let itemclass: string = 'absolute -top-10 -left-10 z-20 ' + showCountClass;
        countDiv.setAttribute('class', itemclass);
        countDiv.style.userSelect = 'none';

        return countDiv;
    }

    function updateMouseFollowPosition(event: MouseEvent) {
        if (countElement != null) {
            countElement.style.left = `${event.pageX + 10}px`; // Offset to avoid cursor overlap
            countElement.style.top = `${event.pageY + 10}px`;
        }
    }

    interact(element).draggable({
        allowFrom: element.querySelector('[data-dnd-draghandle]') ? '[data-dnd-draghandle]' : undefined,
        listeners: {
            start (event) {
                const { target: draggableElement } = event;
                const rect = element.getBoundingClientRect();
                draggableElement.dataset.dndDraggingActive = true;
                element.style.position = 'fixed';
                element.style.top = '0';
                element.style.left = '0';
                position.x = rect.left;
                position.y = rect.top;

                let selectedList = element.dataset.dndSelected
                let count = selectedList.split(',').length; // gets count of items in comma seperated list
                if (count > 1 && showCount === true) {
                    countElement = makeCountElement(count);
                    document.body.appendChild(countElement);
                    document.addEventListener('mousemove', updateMouseFollowPosition);
                }
                render();
            },
            move (event) {
                position.x += event.dx
                position.y += event.dy

                render();
            },
            end (event) {
                position.x = 0;
                position.y = 0;

                document.removeEventListener('mousemove', updateMouseFollowPosition);
                if (countElement != null){
                    document.body.removeChild(countElement);
                }

                element.removeAttribute('style');
                render()
                delete event.target.dataset.dndDraggingActive;
                delete event.target.dataset.dndOverDropZone;
            }
        }
    })
}

export default class Sortable {
    // noinspection JSUnusedGlobalSymbols -- invoked from C#
    init(dropzone: HTMLElement, dropzoneOverride: HTMLElement | null, scrollContainerId: string, listKey: string, group: string, draggableClass: string, showCountClass: string, showCount: boolean, listeners: DotNetObjectReference): SortableContext {
        let childCoords: CoordinateData[] = [];
        const recomputeCoords = () => childCoords = getChildrenCoords(dropzone, scrollContainerId);
        dropzone.dataset.dndDraggableClass = draggableClass;

        const initDraggables = () => {
            Array.from(dropzone.children)
                .map(e => (e as HTMLElement))
                .filter(e => e.dataset.dndGroup && (e.dataset.dndListKey !== listKey))
                .map(e => initDraggable(
                    e as HTMLElement,
                    listKey,
                    showCount,
                    showCountClass,
                    k => listeners.invokeMethodAsync("OnItemSelectedCallback", k),
                    k => listeners.invokeMethodAsync("OnItemShiftSelectedCallback", k),
                    k => listeners.invokeMethodAsync("OnItemCtrlSelectedCallback", k),
                    )
                );
        }

        initDraggables();

        let placeholder: HTMLElement | null = null;

        dropzone.dataset.dndListKey = listKey;
        const renderPlaceholder = (index: number, factory: () => HTMLElement) => {
            if (placeholder?.parentNode === dropzone) {
                dropzone.removeChild(placeholder);
            }
            else {
                placeholder = factory();
                placeholder.removeAttribute('style');
                placeholder.setAttribute('class', dropzone.dataset.dndDraggableClass);
                placeholder.style.opacity = "0.5";
                placeholder.style.transform = '';
                delete placeholder.dataset.dndDraggingActive;
                placeholder.dataset.dndPlaceholder = 'true';
            }

            if (index >= dropzone.children.length) {
                dropzone.appendChild(placeholder);
            } else {
                const referenceChild = dropzone.children[index];
                dropzone.insertBefore(placeholder, referenceChild);
            }
        }

        const clearPlaceholder = () => {
            placeholder?.remove();
            placeholder = null;
        }

        dropzone.dataset.dndGroup = group;
        let dropzoneElement: HTMLElement = dropzone;
        if (dropzoneOverride) dropzoneElement = dropzoneOverride;
        interact(dropzoneElement).dropzone({
            accept: `[data-dnd-group=${group}]`,//Only accept draggables from same group
            overlap: 'pointer',
            ondropactivate: function (event) {
                // add active dropzone feedback
                event.target.dataset.dndDropActive = true;
                recomputeCoords();
            },
            ondragenter: function (event) {
                const { relatedTarget: draggableElement } = event;

                // feedback the possibility of a drop
                dropzone.dataset.dndDropTarget = 'true';
                draggableElement.dataset.dndOverDropZone = true;

                // fire enter event
                const { dndKey, dndGroup } = draggableElement.dataset;
                if (dndGroup === dropzone.dataset.dndGroup) {
                    const _ = listeners.invokeMethodAsync("OnEnterCallback", dndKey);
                }
            },
            ondropmove: function (event) {
                const { relatedTarget: draggableElement } = event;
                const closestIndex= findClosest(getTopLeftCorner(draggableElement), childCoords);
                draggableElement.dataset.dndSortableIndex = "" + closestIndex;
                renderPlaceholder(closestIndex, () => draggableElement.cloneNode(true));
            },
            ondragleave: function (event) {
                const { relatedTarget: draggableElement } = event;

                // remove the drop feedback style
                delete dropzone.dataset.dndDropTarget;
                delete draggableElement.dataset.dndOverDropZone;
                clearPlaceholder();
            },
            ondrop: function (event) {
                const { relatedTarget: draggableElement } = event;

                const { dndKey, dndSelected, dndGroup, dndSortableIndex, dndListKey } = draggableElement.dataset;
                if (dndGroup === dropzone.dataset.dndGroup) {
                    if (dndListKey === dropzone.dataset.dndListKey) {
                        //Moving within the same list
                        const _ = listeners.invokeMethodAsync("OnItemMovedCallback", dndKey, dndSelected, +dndSortableIndex);
                    }
                    else {
                        //Moving from another list
                        listeners.invokeMethodAsync("OnItemRemovedCallback", dndKey, dndSelected);
                        listeners.invokeMethodAsync("OnItemAddedCallback", dndKey, dndSelected, +dndSortableIndex);
                        if (!dropzone.contains(draggableElement)) draggableElement.classList.add('hidden');
                    }
                    clearPlaceholder();
                }
            },
            ondropdeactivate: function (event) {
                // remove active dropzone feedback
                delete event.target.dataset.dndDropActive;
                delete event.target.dataset.dndDropTarget;
            }
        });
        return {
            //Something something poor-man's objects
            update: () => {
                recomputeCoords();
                initDraggables();
            }
        };
    }
}
