import { KeyBehaviorBlocker } from "./KeyBehaviorBlocker";
import { NavLock } from "./NavLock";
import { ActivityDetector } from "./ActivityDetector";
import { DotNetObjectReference } from "./DotnetObjectReference";
import Sortable from "./Sortable";
import FocusScroll from "./FocusScroll";
import ClientsidePostHog from "./ClientsidePostHog";
import ScrollByCell from "./ScrollByCell";
import CopyTableToClipboardHelper from "./CopyTableToClipboardHelper";
import ContextMenuHelper from "./ContextMenuHelper";
import InfiniteScrolling from "./InfiniteScrolling";

export {};

type KeyboardEventArgs = {
    Key: string,
    AltKey: boolean,
    CtrlKey: boolean,
    Repeat: boolean,
    ShiftKey: boolean,
    MetaKey: boolean
}

declare global {
    interface Window {
        FormUtil: {
            postForm: (route: string, formId: string) => Promise<any>
            postRoute: (route: string, body: object) => Promise<any>
        };
        Download: { downloadFile: (filename: string, contentType: string, data: BlobPart) => void };
        FocusUtils: {
            focusElement: (elementId: string) => void
        }
        ScrollUtils: {
            scrollIntoView: (elementId: string) => void,
            scrollElementIntoView: (element: HTMLElement) => void,
            scrollToPosition: (containerId: string, position: number) => void,
            scrollToSelected: (containerId: string, offsetPixels: number) => void,
            scrollSelectionIntoView: (list: HTMLElement | null) => void,
            scrollToElementLocation: (containerId: string, offsetPixels: number) => void
        };
        KeyUtils: {
            initControls: (element: HTMLElement | null, callbackObject: DotNetObjectReference) => void,
            initGlobalListener: (callbackObject: DotNetObjectReference) => void
        };
        InputUtils: {
            initPhoneNumberMask: (element: HTMLElement) => void
        };
        InputName: {
            initNameFilter: (element: HTMLElement) => void
        };
        KeyBehaviorBlocker: KeyBehaviorBlocker
        NavLock: NavLock
        ActivityDetector: ActivityDetector
        Sortable: Sortable
        FocusScroll: FocusScroll
        ScrollByCell: ScrollByCell
        ClientsidePostHog: ClientsidePostHog;
        CopyTableToClipboard: CopyTableToClipboardHelper;
        ContextMenuHelper: ContextMenuHelper;
        InfiniteScrolling: InfiniteScrolling;
    }
}

window.FormUtil = {
    postForm: async function (route: string, formId: string) {
        let res = await fetch(
            route,
            {
                method: "POST",
                body: new FormData(document.querySelector('form#' + formId)),
                headers: {
                    'Require-Preflight': "true" // This is a custom header that we include to ensure a CORS Preflight request is sent. This helps guard against CSRF attacks and be read about here: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests.
                }
            });
        if (res.status === 400) {
            return {
                success: false
            };
        }
        return await res.json();
    },
    postRoute: async function (route: string, body: object) {
        let res = await fetch(
            route,
            {
                method: 'POST',
                body: JSON.stringify(body),
                credentials: 'same-origin',
                headers: {
                    'Require-Preflight': "true", // This is a custom header that we include to ensure a CORS Preflight request is sent. This helps guard against CSRF attacks and be read about here: https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#simple_requests.
                    'Content-Type': 'application/json',
                }
            });
        if (res.status === 400) {
            return {
                success: false
            };
        }
        return await res.json();
    }
}

window.Download = {
    downloadFile: function (filename: string, contentType: string, data: BlobPart) {

        // Create the URL
        const file = new File([data], filename, {type: contentType});
        const exportUrl = URL.createObjectURL(file);

        // Create the <a> element and click on it
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = exportUrl;
        a.download = filename;
        a.target = "_self";
        a.click();

        // We don't need to keep the url, let's release the memory
        URL.revokeObjectURL(exportUrl);
        a.remove();
    }
}

window.FocusUtils = {
    focusElement: function (elementId: string) {
        var element = document.getElementById(elementId);
        if (element) {
            element.focus();
        }
    },
}

window.ScrollUtils = {
    scrollIntoView: function (elementId: string) {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({block: "nearest", behavior: "smooth"});
    },
    scrollElementIntoView: function (element: HTMLElement) {
        element?.scrollIntoView({behavior: "smooth"});
    },
    scrollToPosition: function (containerId: string, position: number) {
        const container = document.getElementById(containerId);
        if (container) container.scrollTop = position;
    },
    // scroll to first element that has data-selected attribute
    scrollToSelected(containerId: string, offsetPixels: number = 0) {
        const container = document.getElementById(containerId);
        const selected = container?.querySelector("[data-selected]");
        if (!selected || !container) return;
        // scroll to the "exact" location (before offset)
        selected.scrollIntoView({block: "nearest"});
        // scroll to the offset location
        const maxScrollTop = container.scrollHeight - container.offsetHeight;
        const selectedPositionRelativeToContainer = selected.getBoundingClientRect().top - container.getBoundingClientRect().top;
        container.scrollTop = Math.min(container.scrollTop + selectedPositionRelativeToContainer + offsetPixels, maxScrollTop);
    },
    scrollSelectionIntoView: function (list: HTMLElement | null) {
        if (!list) return;
        const selected = list.querySelector("[aria-selected='true']");
        selected?.scrollIntoView({block: "nearest"});
    },
    scrollToElementLocation: function (containerId: string, offsetPixels: number) {
        const container = document.getElementById(containerId);
        if (!container) return;
        // scroll to the offset location or the end of the container
        const maxScrollTop = container.scrollHeight - container.offsetHeight;
        container.scrollTop = Math.min(offsetPixels, maxScrollTop);
    }
}
window.KeyUtils = {
    initControls: function (element: HTMLElement | null, callbackObject: DotNetObjectReference) {
        element?.addEventListener('keydown', function (e: KeyboardEvent) {
            if (!('dropdownOpen' in (e.target as HTMLElement).dataset)) {
                return;
            }

            if (["ArrowUp", "ArrowDown", "Enter", "Escape"].includes(e.key)) {
                e.preventDefault();
                callbackObject.invokeMethodAsync('Invoke', e.key)
                    .catch(() => null); //Do nothing with error; they should be handled server-side
            } else if (e.key === "Tab") {
                //Don't prevent default, so the focus can move as expected
                callbackObject.invokeMethodAsync('Invoke', e.key)
                    .catch(() => null); //Do nothing with error; they should be handled server-side
            }
        });
    },
    initGlobalListener: function (callbackObject: DotNetObjectReference) {
        document.addEventListener("keydown", function (e: KeyboardEvent) {
            var jsEvent: KeyboardEventArgs = {
                Key: e.key,
                AltKey: e.altKey,
                CtrlKey: e.ctrlKey,
                MetaKey: e.metaKey,
                Repeat: e.repeat,
                ShiftKey: e.shiftKey
            }
            callbackObject.invokeMethodAsync("Invoke", jsEvent).catch(err => console.log("Error on invoking global listener to .NET", err));
        })
    }
}

window.InputName = {
    initNameFilter: function (element : HTMLElement | null) {
        if(!element.hasAttribute('listener')){
            element?.addEventListener('beforeinput', function (e : InputEvent) {
                if (e.data && e.data[0].match(/[0-9]/)) {
                    e.preventDefault();
                }
            });
            element.setAttribute('listener', 'true');
        }
    }
}

window.InputUtils = {
    initPhoneNumberMask: function (element) {
        const mask = '(999) 999-9999 x999999999999999999999999';

        element.addEventListener('input', function (e) {
            if (element instanceof HTMLInputElement && e instanceof InputEvent) {
                const cursorPosition = element.selectionStart;
                let input = element.value.replace(/\D/g, ''); // Remove non-digits
                input = padMainNumbersWithUnderscores(input);
                element.value = applyMask(input, mask);

                // Restore cursor position
                if (e.inputType === 'deleteContentForward' || e.inputType === 'deleteContentBackward') {
                    element.selectionStart = cursorPosition;
                    element.selectionEnd = cursorPosition;
                }
                else {
                    element.selectionStart = getCursorStartingPosition(element.value);
                    element.selectionEnd = getCursorStartingPosition(element.value);
                }

            }
        });

        element.addEventListener('click', function () {
            if (element instanceof HTMLInputElement) {
                element.selectionStart = getCursorStartingPosition(element.value);
                element.selectionEnd = getCursorStartingPosition(element.value);
            }
        });

        // Apply the initial mask
        if (element instanceof HTMLInputElement) {
            let initialInput = element.value.replace(/\D/g, ''); // Remove non-digits
            initialInput = padMainNumbersWithUnderscores(initialInput);
            element.value = applyMask(initialInput, mask);

            // Restore cursor position
            element.selectionStart = getCursorStartingPosition(element.value);
            element.selectionEnd = getCursorStartingPosition(element.value);
        }
    }
}
window.NavLock = new NavLock();

window.ClientsidePostHog =  new ClientsidePostHog();

function padMainNumbersWithUnderscores(input: string) {
    let paddedInput = input.replace(/\D/g, '');
    let mainNumber = paddedInput.slice(0,10);
    let extension = paddedInput.slice(10);

    let newMainNumber = mainNumber;
    for (let i = 0; i < 10; i++) {
        if (mainNumber.length <= i) {
            newMainNumber += '_';
        }
    }
    return newMainNumber + extension;
}
function applyMask(input: string, mask: string) {
    let result = '';
    let inputIndex = 0;

    for (let i = 0; i < mask.length; i++) {
        if (mask[i] === '9' || mask[i] ==='x') {
            if (inputIndex < input.length) {
                if (mask[i] === 'x') {
                    result += 'x';
                }
                else {
                    result += input[inputIndex];
                    inputIndex++;
                }
            } else {
                break;
            }
        } else {
            result += mask[i];
        }
    }
    return result;
}
function getNumberInputLength(value: string) {
    let digitCount = 0;
    for (let i = 0; i < value.length; i++) {
        if (/\d/.test(value[i])) {
            digitCount++;
        }
    }
    return digitCount;
}
function getCursorStartingPosition(value: string) {
    const inputLen = getNumberInputLength(value);
    console.log(inputLen)
    if (inputLen < 3) {
        return inputLen + 1;
    }
    if (inputLen < 6) {
        return inputLen + 3;
    }
    if (inputLen < 10) {
        return inputLen + 4;
    }
    if (inputLen >= 10) {
        return inputLen + 6;
    }
}

window.KeyBehaviorBlocker = new KeyBehaviorBlocker();
window.ActivityDetector = new ActivityDetector();
window.Sortable = new Sortable();
window.FocusScroll = new FocusScroll();
window.ScrollByCell = new ScrollByCell();
window.CopyTableToClipboard = new CopyTableToClipboardHelper();
window.ContextMenuHelper = new ContextMenuHelper();
window.InfiniteScrolling = new InfiniteScrolling();
