export default class Resize {

    init = (resizableElement: HTMLElement, startHeight: number) => {
        resizableElement.style.height = `${startHeight}px`;
    }

    verticallyResize = (resizableElement: HTMLElement) => {
        let startY: number, startHeight: number;

        const mouseMoveHandler = (e: MouseEvent) => {
            const dy = e.clientY - startY;
            resizableElement.style.height = `${startHeight + dy}px`;
        };

        const mouseUpHandler = () => {
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        const mouseDownHandler = (e: MouseEvent) => {
            startY = e.clientY;
            startHeight = parseInt(window.getComputedStyle(resizableElement).height, 10);
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        resizableElement.querySelector('.resize-handle')?.addEventListener('mousedown', mouseDownHandler);
    };
}
