export default class ScrollByCell {
    initScrollHandler(el: HTMLDivElement) {
        //------------------------------------------------------------ FUNCTIONS ------------------------------------------------------------//
        function wheelListener(e: WheelEvent){
            //this ignores horizontal laptop trackpad movements
            if (Math.abs(e.deltaY) < 10) {
                return;
            }
            e.preventDefault();
            if(e.shiftKey){
                heatgridBody.scrollBy({
                    left: e.deltaY,
                })
            } else {
                heatgridBody.scrollBy({
                    top: e.deltaY,
                })
            }
        }

        function mouseOverCellListener(e: Event) {
            let target = e.target as HTMLElement;
            let rowColKeyElement = target.querySelector('#rowColKey');
            if (!rowColKeyElement) return;
            let rowIndex = rowColKeyElement.innerHTML.split("-")[0];
            let colIndex = rowColKeyElement.innerHTML.split("-")[1];
            let rowLabel = document.querySelector("#rowLabel" + rowIndex) as HTMLElement;
            let colLabel = document.querySelector("#colLabel" + colIndex) as HTMLElement;

            rowLabel.classList.add("hovered");
            colLabel.classList.add("hovered");
        }

        function mouseOutCellListener(e: Event) {
            let target = e.target as HTMLElement;
            let rowColKeyElement = target.querySelector('#rowColKey');
            if (!rowColKeyElement) return;
            let rowIndex = rowColKeyElement.innerHTML.split("-")[0];
            let colIndex = rowColKeyElement.innerHTML.split("-")[1];
            let rowLabel = document.querySelector("#rowLabel" + rowIndex) as HTMLElement;
            let colLabel = document.querySelector("#colLabel" + colIndex) as HTMLElement;

            rowLabel.classList.remove("hovered");
            colLabel.classList.remove("hovered");
        }

        function isCellVisibleInTable(cell: HTMLElement): boolean {
            const rect = cell.getBoundingClientRect();
            const heatgridBodyRect = heatgridBody.getBoundingClientRect();

            return (
                rect.top >= heatgridBodyRect.top &&
                rect.left >= heatgridBodyRect.left &&
                rect.bottom <= heatgridBodyRect.bottom &&
                rect.right <= heatgridBodyRect.right
            );
        }

        function hideRowAndColLabels() {
            //go through all of the cells, find whether or not they are visible, and change the visibility of the corresponding labels accordingly.
            let visibleRows: { [key: string]: boolean } = {};
            let visibleCols: { [key: string]: boolean } = {};

            for (let rowIndex = 0; rowIndex < heatgridBody.children.length; rowIndex++) {
                for (let colIndex = 0; colIndex < heatgridBody.children[0].children.length; colIndex++) {
                    let cell = heatgridBody.children[rowIndex].children[colIndex] as HTMLElement;
                    let rowColKeyElement = cell.querySelector('#rowColKey');
                    let rowKey = rowColKeyElement.innerHTML.split("-")[0];
                    let colKey = rowColKeyElement.innerHTML.split("-")[1];
                    if (visibleRows[rowKey] != true || visibleCols[colKey] != true) {
                        let isVisible = isCellVisibleInTable(cell);
                        if (visibleRows[rowKey] != true) {
                            visibleRows[rowKey] = isVisible;
                        }
                        if (visibleCols[colKey] != true) {
                            visibleCols[colKey] = isVisible;
                        }
                    }
                }
            }

            for (let rowKey in visibleRows) {
                let rowLabel = document.querySelector("#rowLabel" + rowKey) as HTMLElement;
                rowLabel.hidden = !visibleRows[rowKey];
            }

            for (let colKey in visibleCols) {
                let colLabel = document.querySelector("#colLabel" + colKey) as HTMLElement;
                colLabel.parentElement.hidden = !visibleCols[colKey];
            }
        }


        //------------------------------------------------------------ Code to Run on Page Load ------------------------------------------------------------//
        const columnWidth = 44;
        const rowHeight = 34;
        const heatgridBody = el.querySelector('.heatgrid-body') as HTMLDivElement
        const placeholder = el.querySelector(".heatgrid-placeholder") as HTMLDivElement;
        const numColumnsThatWillFit = Math.floor(heatgridBody.offsetWidth / columnWidth);
        const numRowsThatWillFit = Math.floor(heatgridBody.offsetHeight / rowHeight) - 1;
        const numColsToDisplay = Math.min(numColumnsThatWillFit, heatgridBody.children[0].children.length);
        const numRowsToDisplay = Math.min(numRowsThatWillFit, heatgridBody.children.length)

        //If there are fewer columns than there are spaces available, hide overflow x
        if (numColsToDisplay >= heatgridBody.children[0].children.length) {
            heatgridBody.style.overflowX = 'hidden';
            heatgridBody.style.height = numRowsToDisplay * rowHeight + "px";
        } else { //otherwise there will be a bottom scrollbar and we need to increase the height of our heatgrid to accomodate
            heatgridBody.style.height = numRowsToDisplay * rowHeight + 16 +  "px";
        }
        if (numRowsToDisplay >= heatgridBody.children.length){
            heatgridBody.style.overflowY = 'hidden';
            heatgridBody.style.width = numColsToDisplay * columnWidth + "px";
        } else {
            heatgridBody.style.width = numColsToDisplay * columnWidth + 16 + "px";
        }

        //set the row title height equal to the height of the table
        const rowTitle = el.querySelector('#heatgrid-row-label') as HTMLElement;
        rowTitle.style.height = heatgridBody.style.height;
        const columnLabels = el.querySelector('.heatgrid-column-labels') as HTMLDivElement;
        columnLabels.style.overflowX = "visible";
        columnLabels.parentElement.style.width = parseInt(heatgridBody.style.width.slice(0, -2)) + 140 + "px";
        hideRowAndColLabels();


        //------------------------------------------------------------ Set Event Listeners ------------------------------------------------------------//
        for (let rowIndex = 0; rowIndex < heatgridBody.children.length; rowIndex++) {
            for (let colIndex = 0; colIndex < heatgridBody.children[0].children.length; colIndex++) {
                let cell = heatgridBody.children[rowIndex].children[colIndex];
                cell.addEventListener('mouseover', mouseOverCellListener);
                cell.addEventListener("mouseout", mouseOutCellListener);
            }
        }
        heatgridBody.addEventListener('wheel', wheelListener);
        heatgridBody.addEventListener('scroll', hideRowAndColLabels);
        placeholder.hidden = true;
        return {
            destroy: () => {
                heatgridBody.removeEventListener('scroll', hideRowAndColLabels);
                heatgridBody.removeEventListener('wheel', wheelListener);
            }
        }
    }
}
